export const domini = {
  apiKey: "AIzaSyCMF0bY_LPmujT_Pz7vx2oML0g0x2enDd8",
  authDomain: "domini-mix.firebaseapp.com",
  projectId: "domini-mix",
  storageBucket: "domini-mix.appspot.com",
  messagingSenderId: "173411109519",
  appId: "1:173411109519:web:008fe21bbedb6987523efa",
};

export const greenNewDeal = {
  apiKey: "AIzaSyB61XE2q96xG5ztzJyGTXgIcrUqM-QGyRM",
  authDomain: "green-newdeal.firebaseapp.com",
  projectId: "green-newdeal",
  storageBucket: "green-newdeal.appspot.com",
  messagingSenderId: "773169902236",
  appId: "1:773169902236:web:b258ea203dce3dc9ef3359",
};

export const mxConfig = {
  apiKey: "AIzaSyDJImw0nqvfEhKt9POJRuteOEVRXZyA27g",
  authDomain: "parti-2020.firebaseapp.com",
  databaseURL: "https://parti-2020.firebaseio.com",
  projectId: "parti-2020",
  storageBucket: "parti-2020.appspot.com",
  messagingSenderId: "959324853924",
  appId: "1:959324853924:web:0fdb1b2838fb9147b53831",
  measurementId: "G-84TGWTQ100",
};

export const actionPlan = {
  apiKey: "AIzaSyDpu1HPuRbiAHolsS9Eo83d-e4AtpCiXKA",
  authDomain: "seoul-action-plan.firebaseapp.com",
  projectId: "seoul-action-plan",
  storageBucket: "seoul-action-plan.appspot.com",
  messagingSenderId: "435871373109",
  appId: "1:435871373109:web:aa102ea1ea399ce6f31e7c",
  measurementId: "G-E0363VB4SK",
};

export const policyFair = {
  apiKey: "AIzaSyAPaJ3OGvqVEVoTFFfU9ON4SKcfQdq4t5w",
  authDomain: "policy-fair-mix.firebaseapp.com",
  databaseURL: "https://policy-fair-mix.firebaseio.com",
  projectId: "policy-fair-mix",
  storageBucket: "policy-fair-mix.appspot.com",
  messagingSenderId: "636422424475",
  appId: "1:636422424475:web:73b245e758dc628a14c33e",
  measurementId: "G-ZD6NW82J00",
};

export const gyeonggiVillage = {
  apiKey: "AIzaSyCcQMcYC1_p13zv3KTS-B305yVS8bLJ9fM",
  authDomain: "gyeonggi-village-mix.firebaseapp.com",
  databaseURL: "https://gyeonggi-village-mix.firebaseio.com",
  projectId: "gyeonggi-village-mix",
  storageBucket: "gyeonggi-village-mix.appspot.com",
  messagingSenderId: "520788571448",
  appId: "1:520788571448:web:33aa58ad768d8fc302bdcd",
  measurementId: "G-YVGVHWP5MZ",
};

export const sharentingFirebaseConfig = {
  apiKey: "AIzaSyBva_XB1zPdgWcal0-h4ZCDzNbTGhEh_bU",
  authDomain: "sharenting-mix.firebaseapp.com",
  databaseURL: "https://sharenting-mix.firebaseio.com",
  projectId: "sharenting-mix",
  storageBucket: "sharenting-mix.appspot.com",
  messagingSenderId: "1048382260087",
  appId: "1:1048382260087:web:f0e449cc8ce63ea21f9772",
  measurementId: "G-FQGE3EQ6N4",
};
